<template>
  <div class="d-flex align-items-center my-3">
    <div class="avatar mr-3">
      <img
        :src="avatar ? avatar : require(`@/assets/images/user/user-icon.png`)"
        :alt="name"
        class="avatar-50"
      />
    </div>
    <div class="chat-sidebar-name text-left">
      <small class="mb-0 font-weight-bold">
        {{ name }}
        {{ lastname }}
      </small>
      <b-button
        @click="emitDeleteMember"
        variant="outline-danger ml-2 p-1 px-2"
        pill
        v-b-tooltip.top.hover
        :title="$t('app.delete')"
        v-if="showDeleteButton"
      >
        <i class="ri-delete-bin-line pr-0" style="font-size: 11px" />
      </b-button>
      <br />
    </div>
  </div>
</template>
<script>
export default {
  name: "ChatMember",
  props: {
    id: { type: String },
    name: { type: String },
    lastname: { default: "" },
    avatar: { default: null },
    showDeleteButton: { default: false, type: Boolean },
  },
  methods: {
    emitDeleteMember() {
      this.$emit("deleteMember", this.id);
    },
  },
};
</script>
