<template>
  <form
    @submit.prevent="submitNewMessage"
    class="d-flex align-items-center"
    action="javascript:void(0);"
  >
    <div class="chat-attagement d-flex">
      <!-- <a href="javascript:void(0)"
        ><i
          class="fa fa-smile-o pr-3"
          aria-hidden="true"
        ></i
      ></a>
      <a href="javascript:void(0)"
        ><i
          class="fa fa-paperclip pr-3"
          aria-hidden="true"
        ></i
      ></a> -->
    </div>
    <input
      type="text"
      v-model="message"
      class="form-control mr-3"
      placeholder="Escribe aqui tu mensaje"
    />
    <button
      type="submit"
      class="btn btn-primary d-flex align-items-center p-2"
      :disabled="!message"
      :loading="messengerLoading"
    >
      <i class="ri-send-plane-line" aria-hidden="true"></i
      ><span class="d-none d-lg-block">Enviar</span>
    </button>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    currentChatUser: {
      default: null,
    },
  },
  data() {
    return {
      message: "",
    };
  },
  computed: {
    ...mapGetters({
      messengerLoading: "messengerLoading",
    }),
  },
  methods: {
    ...mapActions({
      sendNewMessage: "sendNewMessage",
    }),
    async submitNewMessage() {
      await this.sendNewMessage({
        message: this.message,
        chat_id: this.currentChatUser.id,
      });
      this.message = "";
      this.$emit("messageSended", this.currentChatUser);
    },
  },
};
</script>

<style></style>
