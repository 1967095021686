<template>
  <div class="chat-head">
    <div
      class="d-flex justify-content-between align-items-center bg-white pt-3 pr-3 pb-3"
    >
      <ToggleButton
        :mini-toggle="true"
        :close-button="false"
        toggleShow="#chat-user-detail-popup"
        mediaClass="chat-user-profile"
        v-if="currentChatUser"
      >
        <template v-slot:media>
          <div class="d-flex align-items-center" style="cursor: pointer">
            <img
              :src="getImageChat"
              alt="avatar"
              v-b-tooltip.top.hover
              :title="$t('app.edit')"
              class="avatar-50"
            />
            <h5 class="mb-0 ml-2">
              {{ getNameChat(currentChatUser) }}
            </h5>
          </div>
        </template>
        <template v-slot:body> </template>
      </ToggleButton>
      <ToggleContent
        id="chat-user-detail-popup"
        bodyClass="chatuser-detail"
        center
        v-if="currentChatUser"
      >
        <template v-slot:media>
          <div class="profile-img-edit">
            <b-img
              :src="getImageChat"
              class="profile-pic height-60 width-60"
              alt="avatar"
            ></b-img>
            <div class="p-image" v-if="currentChatUser.is_group">
              <div class="position-relative cursos-pointer" id="pick-image">
                <i class="ri-pencil-line upload-button"></i>
              </div>
              <avatar-cropper
                :upload-handler="selectedFile"
                @changed="setFileName"
                trigger="#pick-image"
                :labels="{
                  submit: $t('app.accept'),
                  cancel: $t('app.cancel'),
                }"
                :cropper-options="{
                  aspectRatio: 1,
                  autoCropArea: 1,
                  viewMode: 1,
                  movable: false,
                  zoomable: false,
                }"
              />
            </div>
          </div>
        </template>
        <template v-slot:title>
          <div class="user-name mt-4">
            <h6 v-if="!loading">
              {{ getNameChat(currentChatUser) }}
            </h6>
            <b-spinner
              style="width: 3rem; height: 3rem"
              v-if="loading"
              variant="primary"
              label="Subiendo"
            >
            </b-spinner>
            <br v-if="loading" />
            <span v-if="loading">
              {{ $t("profile.loading") }}
            </span>
          </div>
        </template>
        <template v-slot:body>
          <section v-if="currentChatUser.is_group">
            <div class="row">
              <div class="col-6 col-md-6 title">{{ $t("chat.name") }}:</div>
              <div class="col-6 col-md-6 text-right">
                {{ currentChatUser.name }}
              </div>
            </div>
            <hr />
            <div class="row" v-if="currentChatUser.description">
              <div class="col-6 col-md-6 title">
                {{ $t("chat.description") }}:
              </div>
              <div class="col-6 col-md-6 text-right">
                {{ currentChatUser.description }}
              </div>
            </div>
          </section>

          <section v-else>
            <div class="row">
              <div class="col-6 col-md-6 title">RUT:</div>
              <div class="col-6 col-md-6 text-right">
                {{ currentChatUser.user.rut }}
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6 col-md-6 title">Correo electrónico:</div>
              <div class="col-6 col-md-6 text-right">
                {{ currentChatUser.user.email }}
              </div>
            </div>
          </section>
        </template>
      </ToggleContent>
      <div class="chat-header-icons d-flex" v-if="false">
        <section>
          <b-button
            @click="$emit('newMember', currentChatUser.id)"
            variant="outline-primary mr-2"
            v-if="currentChatUser.is_group"
          >
            <i class="ri-user-add-line mr-1 pr-0" />
            {{ $t("chat.new-member") }}
          </b-button>
        </section>
        <ToggleButton
          :mini-toggle="true"
          :close-button="false"
          toggleShow="#chat-member-detail-popup"
        >
          <template v-slot:media>
            <b-button
              variant="outline-primary"
              class="ml-2"
              v-if="currentChatUser.is_group"
            >
              <i class="ri-group-line mr-1 pr-0" />
              {{ $t("chat.members") }}
            </b-button>
          </template>
        </ToggleButton>
        <section>
          <b-button
            @click="$emit('closeChat')"
            variant="outline-danger"
            class=""
          >
            <i class="ri-close-line mb-3 pr-0" />
            {{ $t("chat.close") }}
          </b-button>
        </section>
        <ToggleContent
          class="scrolleable"
          id="chat-member-detail-popup"
          bodyClass="chatuser-detail"
          center
        >
          <template v-slot:media>
            <h5>
              {{ $t("chat.members") }}
            </h5>
          </template>
          <template v-slot:body>
            <ChatMember
              v-if="userAuth"
              :id="userAuth.id"
              name="Tu"
              :avatar="userAuth.avatar"
            />
            <ChatMember
              v-for="userMember in currentChatUser.users"
              :key="userMember.id"
              :id="userMember.id"
              :showDeleteButton="userAuth.super_admin_role"
              :name="userMember.name"
              :lastname="userMember.first_lastname"
              @deleteMember="$emit('deleteMember', $event)"
            />
          </template>
        </ToggleContent>
      </div>
    </div>
  </div>
</template>

<script>
import ChatMember from "@/components/Chat/ChatMember";
import ToggleButton from "@/components/Chat/ToggleButton";
import ToggleContent from "@/components/Chat/ToggleContent";
import AvatarCropper from "vue-avatar-cropper";
import { core } from "@/config/pluginInit";

export default {
  components: {
    ToggleButton,
    ToggleContent,
    ChatMember,
    AvatarCropper,
  },
  data() {
    return {
      preview: "",
      fileSelected: null,
      form: {
        avatar: null,
      },
    };
  },
  props: {
    currentChatUser: {
      default: null,
    },
    userAuth: {
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setFileName(file) {
      this.fileSelected = file;
      this.form.avatar = file;
    },
    async selectedFile(cropper) {
      const dataUrl = cropper
        .getCroppedCanvas()
        .toDataURL(this.fileSelected.type);
      const file = core.dataURLtoFile(dataUrl, this.fileSelected.name);
      this.preview = dataUrl;
      this.form.avatar = file;
      this.$emit("uploadAvatar", {
        preview: dataUrl,
        avatar: this.form.avatar,
        chat_id: this.currentChatUser.id,
      });
    },
    getNameChat(chat) {
      if (chat.chat_template) {
        return chat.chat_template.name;
      } else {
        return `${chat.is_group ? chat.name : chat.user.name}  ${
          chat.is_group ? "" : chat.user.first_lastname ?? ""
        }`;
      }
    },
  },
  computed: {
    getImageChat() {
      return this.preview
        ? this.preview
        : this.currentChatUser.avatar
        ? this.currentChatUser.avatar
        : require(`@/assets/images/user/user-${
            this.currentChatUser.is_group ? "group" : "icon"
          }.png`);
    },
  },
};
</script>

<style></style>
