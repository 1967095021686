<template>
  <b-modal id="modal-new-chat" hide-footer>
    <template #modal-header>
      <h4>
        {{ data.is_group ? $t("chat.new-group") : $t("chat.new") }}
      </h4>
    </template>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="new-user-info">
          <b-row class="justify-content-md-center">
            <b-form-group
              v-if="data.is_group"
              class="col-md-12"
              :label="$t('chat.name')"
              label-for="name"
            >
              <ValidationProvider
                :name="$t('chat.name')"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-input
                  v-model="data.name"
                  type="text"
                  :placeholder="$t('chat.name-placeholder')"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </b-form-group>
            <!-- DESCRIPTION -->
            <b-form-group
              v-if="data.is_group"
              class="col-md-12"
              :label="$t('chat.description')"
              label-for="acronym"
            >
              <ValidationProvider
                :name="$t('chat.description')"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-input
                  v-model="data.description"
                  type="text"
                  :placeholder="$t('chat.description-placeholder')"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </b-form-group>
            <b-form-group class="col-md-12" label="Rol" label-for="education">
              <ValidationProvider
                name="Rol"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  v-model="roleSelected"
                  placeholder="Rol"
                  :options="roleCollege"
                  label="name"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                >
                  <template #no-options="{}">
                    {{ $t("app.not-found") }}...
                  </template>
                </v-select>
                <div v-if="errors[0]">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
            </b-form-group>
            <b-form-group
              class="col-md-12"
              :label="$t('chat.member')"
              label-for="education"
            >
              <ValidationProvider
                :name="$t('chat.member')"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  v-model="data.user_receive"
                  :placeholder="$t('app.min-search')"
                  :options="wrappedUsersCollege"
                  @search="(query) => getDebouncedUsers(query)"
                  label="name"
                  :disabled="!roleSelected"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                >
                  <template #option="{ name, first_lastname, email }">
                    <p style="margin: 0">
                      {{ `${name} ${first_lastname}` }}
                    </p>
                    <small>{{ email }}</small>
                  </template>
                  <template #selected-option="{ name, first_lastname, email }">
                    <p style="margin: 0">
                      {{ `${name} ${first_lastname}` }}
                    </p>
                  </template>
                  <template #no-options>
                    {{ loading ? $t("app.loading") : $t("app.not-found") }}...
                  </template>
                </v-select>
                <div v-if="errors[0]">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
            </b-form-group>
            <b-form-group class="col-md-12" label-for="chat_unidirectional">
              <b-form-checkbox
                id="chat_unidirectional"
                v-model="data.chat_unidirectional"
                name="chat_unidirectional"
                class="mt-1"
                :value="1"
                :unchecked-value="0"
              >
                {{ $t("chat.chat-unidirectional") }}
              </b-form-checkbox>
            </b-form-group>
          </b-row>
          <hr />
          <b-button
            :disabled="messengerLoading"
            variant="success"
            class="float-right"
            type="submit"
          >
            <span v-if="messengerLoading">
              <b-spinner small type="grow"></b-spinner>
              {{ $t("app.loading") }}...
            </span>
            <span v-else>
              {{ $t("app.save") }}
            </span>
          </b-button>
          <b-button
            @click="$bvModal.hide('modal-new-chat')"
            class="float-right mr-1"
            variant="outline-danger"
            >{{ $t("app.cancel") }}</b-button
          >
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "modalForm",
  mounted() {
    core.index();
    this.fetchRolesCollege();
  },
  data() {
    return {
      roleSelected: null,
      loading: false,
      debounce: null,
      debounceTime: 300,
      data: {
        name: "",
        description: "",
        is_group: false,
        user_receive: null,
        chat_unidirectional: 0,
      },
    };
  },
  methods: {
    ...mapActions({
      fetchUserCollege: "fetchUserCollege",
      fetchRolesCollege: "fetchRolesCollage",
      storeNewChat: "storeNewChat",
    }),
    async getDebouncedUsers(search) {
      clearTimeout(this.debounce);
      this.debounce = await setTimeout(async () => {
        if (search.length >= 2) {
          this.loading = true;
          try {
            let paramsSearch = {
              search,
            };
            await this.fetchUserCollege(paramsSearch);
          } catch (error) {
            return errror;
          } finally {
            this.loading = false;
          }
        }
      }, this.debounceTime);
    },
    async show({ is_group }) {
      this.reserForm();
      this.data.is_group = is_group;
      this.$bvModal.show("modal-new-chat");
    },
    async onSubmit() {
      let payload = {
        name: this.data.name,
        description: this.data.description,
        user_receive_id: this.data.user_receive.id,
        is_group: this.data.is_group ? 1 : 0,
        chat_unidirectional: this.data.chat_unidirectional ? 1 : 0,
      };
      const resp = await this.storeNewChat(payload);
      this.$bvModal.hide("modal-new-chat");
      this.$emit("refresh");
      if (resp.status === 201) {
        core.showSnackbar("success", this.$t("app.success-create"));
        this.$emit("newChat", resp.data);
      }
      this.reserForm();
    },
    reserForm() {
      this.data = {
        name: "",
        description: "",
        is_group: false,
        user_receive: null,
        chat_unidirectional: 0,
      };
    },
  },
  computed: {
    ...mapGetters({
      messengerLoading: "messengerLoading",
      usersCollege: "getUserCollege",
      roleCollege: "roleCollege",
      collageAuthUser: "collageAuthUser",
    }),
    wrappedUsersCollege() {
      return this.usersCollege
        .map((collegeUser) => ({
          ...collegeUser.user,
          roles: collegeUser.roles,
        }))
        .filter((collegeUser) => {
          if (this.roleSelected) {
            return collegeUser.roles.some(
              (rol) => rol.id === this.roleSelected.id
            );
          }
          return collegeUser;
        });
    },
  },
  watch: {
    roleSelected: {
      handler() {
        this.data.user_receive = null;
      },
    },
  },
};
</script>
