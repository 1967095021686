<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <iq-card body-class="chat-page p-0">
          <template v-slot:body>
            <div class="chat-data-block">
              <b-row>
                <b-col lg="3" class="chat-data-left scroller">
                  <div class="chat-search pt-3 pl-3">
                    <ToggleButton toggleShow="#user-detail-popup">
                      <template v-slot:media>
                        <img
                          :src="
                            user.avatar
                              ? user.avatar
                              : require('@/assets/images/user/user-icon.png')
                          "
                          alt="chat-user"
                          class="avatar-60"
                        />
                      </template>
                      <template v-slot:body>
                        <h5 class="mb-0">
                          {{ user.name }} {{ user.first_lastname ?? "" }}
                        </h5>
                      </template>
                    </ToggleButton>
                    <div class="chat-searchbar mt-2">
                      <div class="form-group chat-search-data m-0">
                        <input
                          type="text"
                          class="form-control round"
                          id="chat-search"
                          placeholder="Busqueda"
                          v-model="search"
                        />
                        <i class="ri-search-line" />
                      </div>
                    </div>
                  </div>
                  <div class="chat-sidebar-channel scroller mt-4 pl-3">
                    <tab-nav
                      :pills="true"
                      :vertical="true"
                      class="iq-chat-ui"
                      id="chat-list-data"
                    >
                      <template v-for="(item, index) in filteredList">
                        <tab-nav-items
                          :id="`v-pills-${index}`"
                          href="#v-pills-home"
                          :ariaControls="`v-pills-${index}`"
                          role="tab"
                          @click="fetchChatMessages(item)"
                        >
                          <template v-slot:title>
                            <ChatItem :item="item" />
                          </template>
                        </tab-nav-items>
                      </template>
                    </tab-nav>
                  </div>
                </b-col>

                <div
                  class="col-lg-9 chat-data p-0 chat-data-right"
                  :style="`background: url(${require('@/assets/images/page-img/100.jpg')}) no-repeat scroll center center;background-size: cover;`"
                >
                  <tab-content id="v-pills-tabContent">
                    <tab-content-item
                      :active="true"
                      aria-labelled-by="v-pills-default"
                    >
                      <template>
                        <!-- Header -->
                        <chat-header
                          :currentChatUser="currentChatUser"
                          :userAuth="user"
                          :loading="messengerLoadingUpload"
                          @closeChat="currentChatUser = null"
                          @uploadAvatar="uploadAvatar"
                          @deleteMember="deleteMemberChat"
                          @newMember="openModalNewMember"
                        >
                        </chat-header>

                        <div class="chat-content scroller">
                          <template
                            v-if="!currentChatUser && !messengerLoading"
                          >
                            <div>
                              <div class="mt-5">
                                <div class="">
                                  <h5
                                    class="mx-auto text-primary p-2 rounded w-75"
                                  >
                                    Selecciona un chat o crea uno nuevo para
                                    empezar
                                  </h5>
                                  <div
                                    class="d-flex flex-col flex-sm-row justify-content-center align-items-center"
                                  >
                                    <div class="mt-4 mx-2">
                                      <b-button
                                        variant="primary"
                                        class="p-2 px-5"
                                        @click="
                                          $refs.modalNewChat.show({
                                            is_group: false,
                                          })
                                        "
                                      >
                                        <h6 class="text-white">
                                          <i class="ri-user-add-line mb-3" />
                                          Crear chat
                                        </h6>
                                      </b-button>
                                    </div>
                                    <div class="mt-4 mx-2">
                                      <b-button
                                        @click="
                                          $refs.modalNewChat.show({
                                            is_group: true,
                                          })
                                        "
                                        variant="primary"
                                        class="p-2 px-5"
                                      >
                                        <h6 class="text-white">
                                          <i class="ri-group-line mb-3" />
                                          Crear grupo
                                        </h6>
                                      </b-button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                          <b-spinner
                            style="width: 3rem; height: 3rem"
                            v-if="messengerLoading"
                            variant="primary"
                            label="Spinning"
                          ></b-spinner>

                          <template v-for="(item, index) in chatMessages">
                            <div
                              class="chat"
                              :class="{
                                'chat-left': item.user_id !== user.id_user,
                              }"
                            >
                              <div class="chat-detail" v-if="currentChatUser">
                                <div class="chat-message">
                                  <small
                                    class="font-weight-bold"
                                    v-if="
                                      currentChatUser.is_group &&
                                      item.user_id !== user.id_user
                                    "
                                  >
                                    {{ getNameUserChat(item.user_id) }}
                                  </small>
                                  <p>{{ item.message }}</p>
                                  <span style="font-size: 10px" class="">{{
                                    moment(item.created_at).format(
                                      "DD/MM/YYYY HH:MM [hrs]"
                                    )
                                  }}</span>
                                </div>
                              </div>
                            </div>
                          </template>

                          <section
                            v-if="
                              !chatMessages.length &&
                              !messengerLoading &&
                              currentChatUser
                            "
                          >
                            <b-badge variant="primary">
                              Sin mensajes actualmente
                            </b-badge>
                          </section>
                        </div>
                        <div
                          class="chat-footer p-3 bg-white"
                          v-if="currentChatUser"
                        >
                          <form-send-message
                            @messageSended="fetchChatMessages"
                            :currentChatUser="currentChatUser"
                          >
                          </form-send-message>
                        </div>
                      </template>
                    </tab-content-item>
                  </tab-content>
                </div>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <create-chat ref="modalNewChat" @newChat="setChat"></create-chat>
    <add-new-member ref="modalNewMember" @newUser="setChat"></add-new-member>
  </b-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { core } from "@/config/pluginInit";
import ChatItem from "@/components/Chat/ChatItem";
import ToggleButton from "@/components/Chat/ToggleButton";
import ToggleContent from "@/components/Chat/ToggleContent";
import CreateChat from "./Components/CreateChat.vue";
import ChatHeader from "./Components/ChatHeader.vue";
import AddNewMember from "./Components/AddNewMember.vue";
import FormSendMessage from "./Components/FormSendMessage.vue";

import moment from "moment";
export default {
  name: "Index",
  components: {
    ChatItem,
    CreateChat,
    AddNewMember,
    ToggleButton,
    ToggleContent,
    FormSendMessage,
    ChatHeader,
  },
  async mounted() {
    core.index();
    await this.getChatsUserAuth();
  },
  data() {
    return {
      search: "",
      currentChatUser: null,
      moment,
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
      chats: "chats",
      messengerLoading: "messengerLoading",
      messengerLoadingUpload: "messengerLoadingUpload",
      chatMessages: "chatMessages",
    }),
    filteredList() {
      return this.chats
        .filter((item) => {
          return item.is_group
            ? item.name.toLowerCase().includes(this.search.toLowerCase())
            : item.user.name.toLowerCase().includes(this.search.toLowerCase());
        })
        .filter((item) => item.last_message);
    },
  },
  methods: {
    ...mapActions([
      "getChatsUserAuth",
      "getChatMessages",
      "deleteUserFromChat",
      "uploadAvatarChat",
    ]),
    async setChat(newChat) {
      await this.getChatsUserAuth();
      let currentChat = this.chats.find((chat) => chat.id === newChat.id);
      this.fetchChatMessages(currentChat);
    },
    async fetchChatMessages(item) {
      this.currentChatUser = item;
      await this.getChatMessages(item.id);
    },
    getNameUserChat(userId) {
      if (this.currentChatUser.users) {
        let user = this.currentChatUser.users.find(
          (user) => user.code === userId
        );
        return user
          ? `${user.name} ${user.first_lastname ? user.first_lastname : ""}`
          : "Usuario eliminado";
      }
    },
    async uploadAvatar(payload) {
      const resp = await this.uploadAvatarChat(payload);
      if (resp?.status == 200) {
        core.showSnackbar("success", this.$t("app.success-edit"));
      }
    },
    deleteMemberChat(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("app.body-confirm-delete"), {
          title: this.$t("chat.member-confirm-delete"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success rounded",
          cancelVariant: "outline-danger rounded",
          okTitle: this.$t("app.yes"),
          cancelTitle: this.$t("app.not"),
          headerClass: "text-center",
          bodyClass: "text-center text-danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.deleteUserFromChat({
              user_to_delete_id: id,
              chat_id: this.currentChatUser.id,
            });
            if (resp.status === 200) {
              this.setChat(this.currentChatUser);
              core.showSnackbar("success", this.$t("chat.member-deleted"));
            }
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    openModalNewMember(id) {
      this.$refs.modalNewMember.show({
        chat_id: id,
      });
    },
  },
};
</script>
<style>
.remove-toggle::after {
  content: unset;
}
.scrolleable {
  position: relative;
  overflow-y: scroll !important;
  overflow-x: hidden;
}
</style>
