<template>
  <b-modal id="modal-new-chat-user" hide-footer>
    <template #modal-header>
      <h4>
        {{ $t("chat.new-member") }}
      </h4>
    </template>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="new-user-info">
          <b-row class="justify-content-md-center">
            <b-form-group
              class="col-md-12"
              :label="$t('user-college.user')"
              label-for="education"
            >
              <ValidationProvider
                :name="$t('user-college.user')"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  v-model="data.user_to_add"
                  :placeholder="$t('app.min-search')"
                  :options="wrappedUsersCollege"
                  @search="(query) => getDebouncedUsers(query)"
                  label="name"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                >
                  <template #no-options>
                    {{ loading ? $t("app.loading") : $t("app.not-found") }}...
                  </template>
                </v-select>
                <div v-if="errors[0]">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-row>
          <hr />
          <b-button
            :disabled="messengerLoading"
            variant="success"
            class="float-right"
            type="submit"
          >
            <span v-if="messengerLoading">
              <b-spinner small type="grow"></b-spinner>
              {{ $t("app.loading") }}...
            </span>
            <span v-else>
              {{ $t("app.save") }}
            </span>
          </b-button>
          <b-button
            @click="$bvModal.hide('modal-new-chat-user')"
            class="float-right mr-1"
            variant="outline-danger"
            >{{ $t("app.cancel") }}</b-button
          >
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddNewMember",
  mounted() {
    core.index();
  },
  data() {
    return {
      data: {
        loading: false,
        debounce: null,
        debounceTime: 300,
        chat_id: null,
        user_to_add: null,
      },
    };
  },
  methods: {
    ...mapActions({
      fetchUserCollege: "fetchUserCollege",
      addUserFromChat: "addUserFromChat",
    }),
    async show({ chat_id }) {
      this.reserForm();
      this.data.chat_id = chat_id;
      this.$bvModal.show("modal-new-chat-user");
    },
    async onSubmit() {
      let payload = {
        user_to_add_id: this.data.user_to_add.id,
        chat_id: this.data.chat_id,
      };
      const resp = await this.addUserFromChat(payload);
      this.$bvModal.hide("modal-new-chat-user");
      this.$emit("refresh");
      if (resp.status === 400) {
        return core.showSnackbar("error", this.$t("chat.user-exist"));
      }
      if (resp.status === 200) {
        core.showSnackbar("success", this.$t("chat.added-successfully"));
        this.$emit("newUser", { id: this.data.chat_id });
      }
      this.reserForm();
    },
    async getDebouncedUsers(search) {
      clearTimeout(this.debounce);
      this.debounce = await setTimeout(async () => {
        if (search.length >= 2) {
          this.loading = true;
          try {
            let paramsSearch = {
              search,
            };
            await this.fetchUserCollege(paramsSearch);
          } catch (error) {
            return errror;
          } finally {
            this.loading = false;
          }
        }
      }, this.debounceTime);
    },
    reserForm() {
      this.data = {
        chat_id: false,
        user_to_add: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      messengerLoading: "messengerLoading",
      usersCollege: "getUserCollege",
      collageAuthUser: "collageAuthUser",
    }),
    wrappedUsersCollege() {
      return this.usersCollege.map((collegeUser) => ({ ...collegeUser.user }));
    },
  },
};
</script>
