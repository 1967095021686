<template>
  <div class="d-flex align-items-center">
    <div class="avatar mr-3">
      <img
        :src="getImageChat"
        :alt="item.is_group ? item.name : item.user.name"
        class="avatar-50"
      />
    </div>
    <div class="chat-sidebar-name">
      <span class="mb-0 font-weight-bold">
        {{ getNameChat(item) }}
      </span>
      <br />
      <section>
        <small v-if="user && getUserOwner && getUserOwner.email !== user.email">
          {{ `${getUserOwner.name} ${getUserOwner.first_lastname}` }}
        </small>
        <small v-else-if="item.last_message">
          {{ getLastMessage(item.last_message.message) }}
        </small>
      </section>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ChatItem",
  props: {
    item: { type: Object },
  },
  methods: {
    getLastMessage(message) {
      return `${message.slice(0, 20)}${message.length >= 20 ? ".." : ""}`;
    },
    getNameChat(item) {
      if (item.chat_template) {
        return item.chat_template.name;
      } else {
        return `${item.is_group ? item.name : item.user.name}  ${
          item.is_group ? "" : item.user.first_lastname ?? ""
        }`;
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    getImageChat() {
      return this.item.avatar
        ? this.item.avatar
        : require(`@/assets/images/user/user-${
            this.item.is_group ? "group" : "icon"
          }.png`);
    },
    getUserOwner() {
      if (this.item.created_by_id) {
        return this.item.users.find(
          (user) => user.code === this.item.created_by_id
        );
      }
      return null;
    },
  },
};
</script>
